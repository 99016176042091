import { pagesPath } from '@/lib/$path';
import Link from 'next/link';
import styles from './404.scss';

export const NotFound: React.FC = () => {
  return (
    <main className="container">
      <div>
        <h1>このページは存在しません</h1>
        <ul className="menu">
          <li>
            <Link href={pagesPath.$url()}>
              <a>トップへ戻る</a>
            </Link>
          </li>
        </ul>
      </div>

      <style jsx>{styles}</style>
    </main>
  );
};
